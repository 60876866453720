.vs-editor-toolbar {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    gap: 0.5 rem;
}

@media screen and (max-width: 600px) {
    .vs-editor-toolbar {
        flex-basis: 50%;
    }
    .languageName {
        margin-right: auto;
    }
}