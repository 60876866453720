body {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    color: whitesmoke;
    background-color: rgb(20, 21, 23);
}

.bottom-svg {
    background-image: url(./assets/svgs/wave.svg);
    position: fixed;
    width: 100%;
    bottom: 0;
}

button {
    color: rgb(var(--text-color));
    border: 2px double transparent;
    background-image: linear-gradient(rgb(13, 14, 33), rgb(13, 14, 33)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192));
    background-origin: border-box;
    background-clip: padding-box, border-box;
}