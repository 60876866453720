button {
    z-index: 1;
    position: relative;
    font-size: inherit;
    font-family: inherit;
    color: white;
    padding: 0.5em 1em;
    outline: none;
    border: none;
    background-color: hsl(236, 32%, 26%);
    overflow: hidden;
    transition: color 0.4s ease-in-out;
}

button::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 100%;
    left: 100%;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background-color: hsl(350, 64%, 45%);
    transform-origin: center;
    transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
    transition: transform 0.45s ease-in-out;
}

button:hover {
    cursor: pointer;
    color: #161616;
}

button:hover::before {
    transform: translate3d(-50%, -50%, 0) scale3d(15, 15, 15);
}

.circle {
    position: absolute;
    border-radius: 50%;
    background: red;
    animation: ripple 15s infinite;
    box-shadow: 0px 0px 1px 0px #508fb9;
}

.small {
    width: 200px;
    height: 200px;
    left: -100px;
    bottom: -100px;
}

.medium {
    width: 400px;
    height: 400px;
    left: -200px;
    bottom: -200px;
}

.large {
    width: 600px;
    height: 600px;
    left: -300px;
    bottom: -300px;
}

.xlarge {
    width: 800px;
    height: 800px;
    left: -400px;
    bottom: -400px;
}

.xxlarge {
    width: 1000px;
    height: 1000px;
    left: -500px;
    bottom: -500px;
}

.shade1 {
    opacity: 0.2;
}

.shade2 {
    opacity: 0.5;
}

.shade3 {
    opacity: 0.7;
}

.shade4 {
    opacity: 0.8;
}

.shade5 {
    opacity: 0.9;
}

@keyframes ripple {
    0% {
        transform: scale(0.8);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(0.8);
    }
}